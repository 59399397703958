import 'swiper/swiper-bundle.css';
import 'flatpickr/dist/flatpickr.min.css';
import '../node_modules/tom-select/dist/css/tom-select.min.css';
import '../sdist/tailwind.css';
import '../node_modules/photoswipe/dist/photoswipe.css';
import '../node_modules/photoswipe/dist/default-skin/default-skin.css';
import './main.scss'

import Swiper, { Navigation, Pagination, Thumbs} from 'swiper';
import morph from '@alpinejs/morph';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import 'htmx.org';
import './window_imports.js';
import 'htmx.org/dist/ext/json-enc.js';

import Countable from 'countable';

import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de";

import TomSelect from 'tom-select/dist/esm/tom-select';
import 'tom-select/dist/esm/plugins/remove_button/plugin';

import './quill_snow.css';
import Quill from 'quill';


window.Alpine = Alpine
Alpine.plugin(morph);


const TOOLBAR_CONFIG = [
  [{ header: ['1', '2', false] }],
  ['bold', 'italic', 'underline', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean']
];

const SIMPLE_TOOLBAR_CONFIG = [
  ['bold', 'italic', 'underline', 'link'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean']
];


document.addEventListener('alpine:init', () => {
  Alpine.data('toggleExtraFieldChecked', (initial, condition) => ({
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="value"]:checked');
        if (node) {
          this.value = node.value;
        }
      }
    },
  }));

  Alpine.data('toggleInnerExtraFieldChecked', (initial, condition) => ({
    innerValue: initial,
    isOpen() { return condition(this.innerValue); },
    init() {
      if (Array.isArray(initial)) {
        this.innerValue = [...this.$el.querySelectorAll('[x-model="innerValue"]:checked')].map(node => node.value);
      } else {
        let node = this.$el.querySelector('[x-model="innerValue"]:checked');
        if (node) {
          this.innerValue = node.value;
        }
      }
    },
  }));

  Alpine.data('toggleExtraFieldSelected', (initial, condition) => ({
    value: initial,
    isOpen() { return condition(this.value); },
    init() {
      if (Array.isArray(initial)) {
        this.value = [...this.$el.querySelectorAll('[x-model="value"] option[selected]')].map(node => node.value);
      } else {
        console.log(this.$el);
        this.value = this.$el.querySelector('[x-model="value"] option[selected]').value;
      }
    },
  }));

  Alpine.data('toggleSegments', (otherCondition, ACondition, BCondition, CCondition, DCondition, exclude) => ({
    value: [],
    hasOther() { return otherCondition(this.value); },
    hasA() { return ACondition(this.value); },
    hasB() { return BCondition(this.value); },
    hasC() { return CCondition(this.value); },
    hasD() { return DCondition(this.value); },
    excluded() { return this.$el.value === exclude; },
    init() {
      this.value = [...this.$el.querySelectorAll('[x-model="value"]:checked')].map(node => node.value);
    },
  }));

  Alpine.data('multiselect', () => ({
    value: [],
    init() {
      let settings = {};
      new TomSelect(this.$el, settings);
    }
  }));

  Alpine.data('autocomplete', () => ({
    value: [],
    init() {
      let settings = {
        create: false,
        sortField: 'text'
      };
      new TomSelect(this.$el, settings);
    }
  }));

  window.CountingField = function (limit, direction, ressource) {
    return {
      limit: limit,
      direction: direction || 'down',
      ressource: ressource || 'all',
      counter: 0,
      count() { return (this.direction === 'down') ? this.limit - this.counter : this.counter },
      reached() { return (this.direction === 'down') ? this.counter === 0 : this.counter >= this.limit },
      init() {
        let node = this.$refs.field;
        if (node) {
          Countable.on(node, counter => { this.counter = counter[this.ressource] })
        }
      }
    }
  }
});

let dates = flatpickr(".datepicker_date", {
  "locale": German,
  enableTime: false,
  dateFormat: "d.m.Y",
});

let times = flatpickr(".datepicker_time", {
  "locale": German,
  enableTime: true,
  noCalendar: true,
  dateFormat: "H:i",
});

function reloadFlatpickr() {
  if (dates) {
    dates.forEach(
      date => date.destroy()
    );
  }

  if (times) {
    times.forEach(
      time => time.destroy()
    );
  }

  dates = flatpickr(".datepicker_date", {
    "locale": German,
    enableTime: false,
    dateFormat: "Y-m-d",
  });
  times = flatpickr(".datepicker_time", {
    "locale": German,
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
  });
}

let renderFlatpicker = (el) => {
  let picker = flatpickr(el, {
    "locale": German,
    enableTime: true,
    dateFormat: "d.m.Y H:i",
  })
}

let renderDateFlatpicker = (el) => {
  let picker = flatpickr(el, {
    "locale": German,
    enableTime: false,
    dateFormat: "d.m.Y",
  })
}


let renderTomSelect = (el) => {
  let settings = {};
  new TomSelect(el, settings);
}

let renderCreateSelect = (el) => {
  let settings = {
    create: true,
    createOnBlur: true,
    sortField: 'text'
  };
  new TomSelect(el, settings);
};

document.querySelectorAll('.multi-select').forEach(renderTomSelect);
document.querySelectorAll('.create-select').forEach(renderCreateSelect);

window.reloadFlatpickr = reloadFlatpickr;
window.renderTomSelect = renderTomSelect;
window.renderCreateSelect = renderCreateSelect;
window.renderFlatpicker = renderFlatpicker;
window.renderDateFlatpicker = renderDateFlatpicker;


Alpine.data('loadQuill', (initial) => ({
  init() {
    let quill = new Quill(this.$refs.quill, {
      placeholder: 'Hier Text eingeben ...',
      modules: {
        toolbar: SIMPLE_TOOLBAR_CONFIG
      },
      theme: 'snow'
    })
    quill.root.innerHTML = initial;
    this.$refs.textarea.value = initial;
    this.$refs.textarea.style.display = "none";

    quill.on('text-change', () => {
      this.$refs.textarea._x_model.set(quill.root.innerHTML);
    })
  }
}));

Alpine.data('initFormset', () => ({
  init() {
    this.$el.querySelectorAll('[data-simple-quill]').forEach(SimpleQuill);
    this.$el.querySelectorAll('.multi-select').forEach(renderTomSelect);
    this.$el.querySelectorAll('.create-select').forEach(renderCreateSelect);
  }
}));

Alpine.plugin(collapse);
Alpine.start();




  function CountableField(textarea) {
    var countDisplay = document.getElementById(textarea.id + "_counter");
    var countDown = false;
    var minCount, maxCount;
    var hasQuill = textarea.getAttribute("data-quill") === 'true';

    if (textarea != null && countDisplay != null) {
      minCount = textarea.getAttribute("data-min-count");
      maxCount = textarea.getAttribute("data-max-count");
    }

    if (hasQuill) {
      // hide textarea
      textarea.style.display = "none";

      // add editor container
      var quillContainer = document.createElement("div");
      quillContainer.setAttribute("id", textarea.id + "_container");
      quillContainer.setAttribute("class", "w-full bg-white mt-0.5");
      textarea.parentNode.insertBefore(quillContainer, textarea.nextSibling);

      // add editor div
      var quillEditor = document.createElement("div");
      quillEditor.setAttribute("id", textarea.id + "_editor");
      quillContainer.appendChild(quillEditor);

      // load data from textarea
      quillEditor.innerHTML = textarea.value;

      var quill = new Quill(quillEditor, {
        placeholder: 'Hier Text eingeben ...',
        modules: {
          toolbar: TOOLBAR_CONFIG
        },
        theme: 'snow'
      });

      quill.on('text-change', function (delta, oldDelta, source) {
        if (source === 'user') {
          Countable.count(quill.root.innerHTML, updateCharatersLeft, { stripTags: true });
          textarea.value = quill.root.innerHTML;
        }
      });

      Countable.count(quill.root.innerHTML, updateCharatersLeft, { stripTags: true });

    } else {
      Countable.on(textarea, updateCharatersLeft);
    }

    function updateCharatersLeft(counter) {
      var count = counter["all"];
      var left = maxCount - count;
      countDisplay.getElementsByClassName("text-count-current")[0].innerHTML = left;
      if (left < 0)
        countDisplay.className = "text-count text-is-over-max";
      else
        countDisplay.className = "text-count";
    }
  }

  function SimpleQuill(textarea) {
    textarea.style.display = "none";

    // add editor container
    var quillContainer = document.createElement("div");
    quillContainer.setAttribute("id", textarea.id + "_container");
    quillContainer.setAttribute("class", "w-full bg-white mt-0.5");
    textarea.parentNode.insertBefore(quillContainer, textarea.nextSibling);

    // add editor div
    var quillEditor = document.createElement("div");
    quillEditor.setAttribute("id", textarea.id + "_editor");
    quillContainer.appendChild(quillEditor);

    // load data from textarea
    quillEditor.innerHTML = textarea.value;

    var quill = new Quill(quillEditor, {
      placeholder: 'Hier Text eingeben ...',
      modules: {
        toolbar: SIMPLE_TOOLBAR_CONFIG
      },
      theme: 'snow'
    });

    quill.on('text-change', function (delta, oldDelta, source) {
      if (source === 'user') {
        textarea.value = quill.root.innerHTML;
      }
    });
  }

(function () {
  document.addEventListener('DOMContentLoaded', function (e) {
    ;
    [].forEach.call(document.querySelectorAll('[data-count]'), CountableField);
    [].forEach.call(document.querySelectorAll('[data-simple-quill]'), SimpleQuill);
  })

})()
